
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import utilMixins from '@/mixins';
import * as util from '@/util';
import header from '@/vuex/header';
import session from '@/vuex/session';
import UtilPopup from "@/components/util/Popup.vue";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    UtilPopup,
    CommonText,
  }
})
export default class Main extends Mixins(utilMixins) {
  get mail() {
    return header.mail;
  }
  set mail(val) {
    header.setMail(val);
  }

  get is_display() {
    return header.is_open_pass_dialog;
  }
  set is_display(val: boolean) {
    header.setIsOpenPassDialog(val);
  }

  cancel() {
    this.is_display = false;
  }
  send() {
    if (this.mail == null) {
      dialog.openErrorDialog({error: [this.$t('error.reset_password_enter_mail') as string]});
    } else {
      header.sendPassreset(this.$route.params.company_code);
    }
  }
}
