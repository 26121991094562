
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Icon extends Vue {
  @Prop() name; //.svgの前の部分まで
  @Prop() color; //#を入れたカラーコードか、rgbなど
  @Prop() size; //数字のみ pxは後付けされる 高さと横幅は同じ前提
  @Prop() spsize; //数字のみ pxは後付けされる 高さと横幅は同じ前提

  get href() {
    return "/img/common/"+this.name+".svg#"+this.name;
  }

  get style() {
    return {
      "--size": this.size+"px",
      "--spsize": this.spsize+"px",
      "--color": this.color,
    };
  }
}
